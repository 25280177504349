/**
 * 导入config请导入本文件
 * v2、v3是特殊配置，在index中会覆盖本文件中的default字段
 */
import cookies from "./cookies"
// 显示pro样式的域名
const ProHosts = ['tianjipro.zmeng123.cn']
// 显示天幕样式的域名
const TmHosts = ['www.tianmu123.cn','www2.tianmu123.cn']
// 寄递大数据治理平台域名
const JDHosts = ['jidi.zmeng123.cn']
// 允许切换版本的域名，其他不允许
const VersionSwitchWhiteHosts = ['tj-dev.zmeng123.com', 'tn-dev.zmeng123.com', 'tianji.zmeng123.cn', 'tn.zmeng123.cn']

const ProjectList =[
    {
        isTmHost:true,
        hosts:TmHosts,
        defaultName:"天幕",
        defaultTitle:"天幕-公安大数据侦查平台",
        defaultLogo:require('@/assets/images/login/tm-logo.png'),
        defaultIcon:require('@/assets/images/login/tm-logo.png'),
    },{
        isJdHost:true,
        hosts:JDHosts,
        defaultName:"寄递大数据治理平台",
        defaultTitle:"寄递大数据治理平台",
        defaultLogo:null,
        defaultIcon:null,
    }
]

// 多版本通用配置
let Config = {
    // cookiesName
    cookies,
    // 使用人脸识别
    useFaceCheck: true,
    // 允许切换版本的域名
    versionSwitchWhiteHosts: VersionSwitchWhiteHosts,
    // 是否是pro域名： 只有pro域名才能用pro的
    isProHost: ProHosts.includes(window.location.hostname),
    // 天幕
    isTmHost: TmHosts.includes(window.location.hostname),
    // 寄递大数据治理平台
    isJdHost:JDHosts.includes(window.location.hostname),
    // 百度地图的AK
    // 备用AK ForTqfObcn2I7xsuqyXHSvNQxA4ntuKu
    baiduAk: "wBzxY8gD6Heh30YiQiscHHCU",
    // 百度地图备用key
    // baiduAk: "ForTqfObcn2I7xsuqyXHSvNQxA4ntuKu",
    // 是否为内网环境
    IS_PRIVATE: process.env.VUE_APP_ENV === 'PRIVATE',
    // ------------
    // 平台配置【从后端请求，默认写null即可】
    projectInfo: null,
    // 如果需要写死，请在打开这里的配置
    // projectInfo: {
    //     logo: { url: "/img/default_logo.089b5427.png" },
    //     title: { content: "天迹", content_v2: "天迹", child_content_v2: "" }
    // },
    // ------------
    
    // 防止水印重绘的id白名单
    watermarkWhiteDom: [ '#title-loop_notice_box' ]
    

    // ...
    // ... includ v2
    // or
    // ... includ v3
}


// 合并本文件配置和特殊配置
Object.assign(Config, require(`./${process.env.VUE_APP_BUILD_VERSION}.config.js`)['default'])

const project =  ProjectList.find(p=>p.hosts.includes(window.location.hostname))
if(project){
   Object.assign(Config,project)
}
export const projectTypeList = [
    { // 0
        loginPath:'/login',
    },{// 1
        loginPath:'/jzlogin',
    },{// 2
        loginPath:'/yslogin',
        title:'一所-虚实结合电子围栏系统'
    },{// 3
        loginPath:'/jdlogin',
        title:'经济犯罪寄递线索研判'
    },{// 4
        loginPath:'/prologin',
        title:'大数据综合作战平台'
    },{// 5
        loginPath:'/tylogin',
        title:'天眼数智系统'
    },{// 6
        loginPath:'/sjlogin',
        title:'所骥',
    },{// 7
        loginPath:'/aqlogin',
        title:'安擎',
    },{// 8
        loginPath:'/tjlogin',
        title:'天谏',   
    },{// 9
        loginPath:'/tqlogin',
        title:'天穹',
    },{// 10
        loginPath:'/jdlogin_v2',
        title:'寄递大数据治理平台',
    },{// 11
        loginPath:'/pdlogin',
        title:'天迹',
    }
]
// 根据登录页更改配置
export const getConfig = ()=>{
    let loginType = localStorage.getItem('login_type')
    if(!loginType){
        loginType=projectTypeList.findIndex(item=>location.pathname.includes(item.loginPath))
        if(loginType==-1)return Config
    }
    const title = projectTypeList[loginType].title
    if(title){
        Config.defaultName = title
        Config.defaultTitle = title
    }
    // 如果是一所项目
    if(loginType=='2'){
        Config.defaultIcon = require('@/assets/images/login/ys-logo.png')
        Config.defaultLogo = require('@/assets/images/login/ys-logo.png')
    }else if(loginType=='3'){
        Config.defaultIcon = ''
    }else if(loginType=='4'){
        Config.defaultIcon = ''
        Config.isProHost = true
    }else if(loginType=='5'){
        Config.defaultIcon = ''
        Config.defaultLogo = ''
    }else if(loginType=='6'){
        Config.defaultIcon = require('@/assets/images/login/sj-logo.png')
        Config.defaultLogo = require('@/assets/images/login/sj-logo.png')
    }else if(loginType=='7'){
        Config.defaultIcon = require('@/assets/images/login/aq-logo.png')
        Config.defaultLogo = require('@/assets/images/login/aq-logo.png')
    }else if(loginType=='8'){ // 天谏
        Config.defaultIcon = require('@/assets/images/login/tianjian-logo.png')
        Config.defaultLogo = require('@/assets/images/login/tianjian-logo.png')
    }else if(loginType=='9'){ // 天穹
        Config.defaultIcon = ''
        Config.defaultLogo = ''
    }
    return Config
}
// 不同登录页
export function getLoginPath(){
    const loginType =  localStorage.getItem('login_type')
    let p = projectTypeList[loginType]
    if(p){
      return p.loginPath||'/login'
    }
    return '/login'
  }

// 导出
export default Config